<template>
    <div class="dF fC f1 px-4 pb-4 hide-scrollbar" style="background:var(--light-bg-gray); width:100%; overflow-y:scroll">
        <bh-loading :show="loading" />
        <div class="dF py-3" style="justify-content:flex-end">
            <i @click="changeDisplay('block')" :style="display == 'block'? 'color:var(--orange)': 'color:#D3D0D6'"  style="cursor:pointer; font-size:25px" class="mr-3 fa fa-th" />
            <i @click="changeDisplay('list')" :style="display == 'list'? 'color:var(--orange)': 'color:#D3D0D6'" style="cursor: pointer; font-size:25px" class="fa fa-th-list" />
        </div>
        <UnitList @deleteUnit="deleteUnit" @duplicateUnit="duplicateUnit" @previewImage="previewImage" :units="currentUnits" v-if="units.length && display == 'list'"/>
        <UnitBlock @deleteUnit="deleteUnit" @duplicateUnit="duplicateUnit" @previewImage="previewImage" :units="currentUnits" v-else-if="units.length && display == 'block'" />
        <EmptyApp :img="require(`@/assets/condo-logo.png`)" :text="'You don\'t have any Units. Add to get started'" v-if="!units.length" />
        <a-modal
            :visible="visible"
            :footer="null"
            @cancel="closePreview()"
            :centered="true"
            :width="780">
            <a-tabs v-model="previewTab">
                <a-tab-pane key="1" tab="Unit Details">
                    <div v-if="Object.keys(previewObject).length != 0">
                        <h3>{{previewObject.unitNumber}}</h3>
                        <div class="grid-display py-3" style="border-top:3px solid #000; border-bottom:1px solid #000">
                            <div v-if="previewObject.packages[0].price">
                                <div>Price</div>
                                <div>{{previewObject.packages[0].price && previewObject.packages[0].price.toLocaleString("US") || 0}}</div>
                            </div>
                            <div>
                                <div>Sq. Ft.</div>
                                <div>{{previewObject.packages[0].sqft || 0}}</div>
                            </div>
                            <div>
                                <div>Bedrooms</div>
                                <div>{{previewObject.packages[0].beds || 0}}</div>
                            </div>
                            <div>
                                <div>Bathrooms</div>
                                <div>{{previewObject.packages[0].baths || 0}}</div>
                            </div>
							<div>
                                <div>Powder Rooms</div>
                                <div>{{previewObject.packages[0].powderRooms || 0}}</div>
                            </div>
                            <div>
                                <div>View</div>
                                <div>{{!previewObject.packages[0].exposure || previewObject.packages[0].exposure == 'none' ? 'N/A':previewObject.packages[0].exposure}}</div>
                            </div>
                            <div>
                                <div>Floor</div>
                                <div>{{typeof previewObject.unitGroup == 'object' ? previewObject.unitGroup.name:unitGroups[previewObject.unitGroup].name}}</div>
                            </div>
                            <div>
                                <div>Occupancy Date</div>
                                <div>{{!previewObject.packages[0].occupancy ? 'N/A':formatDate(previewObject.packages[0].occupancy)}}</div>
                            </div>
                        </div>
                    </div>
					<div class="mt-3">
                        <h4 class="mb-3">Thumbnail Image</h4>
                    	<div style="width:80%; padding-left:5%; padding-right:5%; margin:auto">
                        	<img class="mt-3" :src="image" style="width:100%; object-fit:contain"/>
                    	</div>
                    </div>
                    <hr class="mt-3" v-if="Object.keys(previewObject).length != 0 && previewObject.packages[0] && previewObject.packages[0].floors && previewObject.packages[0].floors.length">
                    <div v-if="Object.keys(previewObject).length != 0 && previewObject.packages[0] && previewObject.packages[0].floors && previewObject.packages[0].floors.length">
                        <h4 class="mb-3">Floor Plan</h4>
                        <div class="dF">
                            <div class="ml-3 dF aC" v-if="previewObject.packages[0] && previewObject.packages[0].floors && previewObject.packages[0].floors.length">
                                <div @click="changeFloor(floorI)" style="cursor:pointer" class="ml-3" :style="chosenFloor == floorI ? '':'color:#A3A5AD'" v-for="(floor, floorI) in previewObject.packages[0].floors" :key="floorI">
                                    <span v-if="floorI == 0">Main Floor Plan</span>
                                    <span v-else>Level {{floorI+1}} Floor Plan</span>
                                </div>
                            </div>
                        </div>
                        <div class="mt-3 dF aC jC" v-if="previewObject.packages[0] && previewObject.packages[0].floors && previewObject.packages[0].floors.length" style="justify-content:space-between">
                            <div :style="chosenFloor == 0 ? 'border-color:#98999A; color:#98999A':''" @click="prevFloorplan" class="px-2 py-3" style="border:1px solid #000; cursor:pointer"><a-icon type="left" /></div>
                            <img style="width:500px; max-height:600px; object-fit:contain" :src="previewObject.packages[0].floors[chosenFloor].image" />
                            <div :style="chosenFloor == previewObject.packages[0].floors.length-1 ? 'border-color:#98999A; color:#98999A':''" @click="nextFloorplan(previewObject.packages[0].floors.length)" class="px-2 py-3" style="border:1px solid #000; cursor:pointer"><a-icon type="right" /></div>
                        </div>
                    </div>
                </a-tab-pane>
                <a-tab-pane v-if="previewObject.media && previewObject.media.mediaImage && previewObject.media.mediaImage.length" key="2" tab="Media">
                    <div class="w-full aC dF jC" style="justify-content:space-between">
                        <div @click="changeImage('prev')" :style="chosenImage == 0 ? 'border-color:#98999A; color:#98999A':'cursor:pointer'"  class="px-2 py-3" style="border:1px solid #000;"><a-icon type="left" /></div>
                        <img style="width:500px; max-height:600px; object-fit:contain" :src="previewObject.media.mediaImage[chosenImage].url" />
                        <div @click="changeImage('next')" :style="chosenImage == previewObject.media.mediaImage.length - 1 ? 'border-color:#98999A; color:#98999A':'cursor:pointer'" class="px-2 py-3" style="border:1px solid #000;"><a-icon type="right" /></div>
                    </div>
                    <div class="dF aC mt-8 hide-scrollbar" style="overflow-x: scroll;">
                        <div @click="chosenImage = imageI" style="cursor:pointer" :style="imageI == chosenImage ? 'border: var(--orange) 2px solid':''" class="mr-3" v-for="(image,imageI) in previewObject.media.mediaImage" :key="imageI" :value="imageI">
                            <img style="width:120px; height:70px; object-fit:cover" :src="image.url" />
                        </div>
                    </div>
                </a-tab-pane>
            </a-tabs>
        </a-modal>
    </div>

</template>

<script>
import bhLoading from 'bh-mod/components/common/Loading'
import EmptyApp from 'bh-mod/components/common/EmptyApp'
import UnitList from '@/components/condounits/UnitList'
import UnitBlock from '@/components/condounits/UnitBlock'
import AppFooter from 'bh-mod/components/common/AppFooter'
export default {
    components:{EmptyApp,UnitList, UnitBlock, AppFooter, bhLoading},
    data(){
        return{
            loading:false,
            visible:false,
            image:'',
            unitList:Object.values(this.$store.state.condoUnits.units),
            emptyUnit:'',
            currentFloor:1,
            totalFloor:10,
            previewObject:{},

            chosenFloor:0,
            chosenImage:0,
            previewTab:'1'
        }
    },
    computed:{
        currentCrumbs(){
            let crumbs = []
            let floor = this.$store.state.condoUnits.floors.find(x => x.order == this.$store.state.condoUnits.currentFloor)
            if (floor) crumbs.push({name:floor.name, id:floor.id})
            if (this.$store.state.condoUnits.currentUnitCategory != '' && this.$store.state.condoUnits.currentUnitCategory != 'all') crumbs.push({name:this.$store.state.condoUnits.currentUnitCategory})
            return crumbs
        },
        unitGroups() {
            let uG = {}
            this.$store.state.floors.forEach(floor => {
                uG[floor.id] = floor
            })
            return uG
        },
        storeUnits() {
            return this.$store.state.condoUnits.units
        },
        display(){
            return this.$store.state.condoUnits.display
        },
        currentUnits(){
            return this.$store.getters.currentUnits
        },
        units(){
            return Object.values(this.$store.state.condoUnits.units)
        },
        instance(){
          return this.$store.state.instance
        },
    },
    watch:{
        currentFloor:{
            handler(val) {
                console.log('changeee')
            }
        },
        currentCrumbs:{
            handler(val) {
                this.$store.commit('SET_CRUMBS', val)
            }
        }
    },
    methods:{
        changeImage(type) {
            if (type == 'prev') {
                if (this.chosenImage > 0) {
                    this.chosenImage -= 1
                }
            } else if (type == 'next') {
                if (this.chosenImage < this.previewObject.media.mediaImage.length-1) {
                    this.chosenImage += 1
                }
            }
        },
        closePreview(){
            this.visible = false
            this.chosenFloor = 0
            this.chosenImage = 0
            this.previewTab = '1'
        },
        nextFloorplan(length){
            if(this.chosenFloor < length-1) {
                this.chosenFloor = this.chosenFloor + 1
            }
        },
        prevFloorplan(){
            if (this.chosenFloor > 0) {
                this.chosenFloor = this.chosenFloor - 1
            }
        },
        changeFloor(index) {
            this.chosenFloor = index
        },
        formatDate(s){
            let numDate = new Date(s)
            let month = numDate.getMonth()+1
            let day = numDate.getDate()
            let year = numDate.getFullYear()
            // let time = numDate.getTime()
            // let formatTime = this.moment(time).format('HH:mm')
            let finalCreatedDate = month+'/'+day+'/'+year
            return finalCreatedDate
        },
        deleteUnit(value) {
            this.loading = true
            this.$api.delete(`/units/${this.instance.id}/${value}`, {buildingType:'condounits'}).then( ({data}) => {
                this.$store.commit('DELETE_UNIT', data.id)
                this.loading = false
                }).catch( err => {
                    console.error('errror', err)
            })
        },
        setLoading(value) {
            this.loading = value
        },
        changeDisplay(item) {
            if (this.display != item) {
                this.$store.commit('CHANGE_DISPLAY', item)
            }
        },
        previewImage(id) {
            this.visible = true
            this.image = this.storeUnits[id].image
            console.log(this.storeUnits[id])
            this.previewObject = JSON.parse(JSON.stringify(this.storeUnits[id]))
        },
        prevFloor() {
            if (this.currentFloor = 1) return this.$message.error('The smallest Floor Level is 1')
            else this.currentFloor -= 1
        },
        nextFloor() {
            if (this.currentFloor = totalFloor) return this.$message.error(`The last Floor Level is ${totalFloor}`)
            else this.currentFloor -= 1
        },
        // previewUnit(id) {
        //     console.log('previewUnit', id)
        //     for (var i in this.units) {
        //         if (this.units[i].id == id) this.image = this.units[i].image
        //     }
        //     this.visible = true
        // },
        editUnit(id) {
            console.log('editUnit', id)
            this.$router.push(`/unitdetails/${id}`)
        },
        duplicateUnit(value) {
            let unit = JSON.parse(JSON.stringify(this.storeUnits[value]))
            let {id, _id, slug, createdAt, updatedAt, ...object} = unit
            object.packages = object.packages.map( ({id,_id,...pkg}) => {
                pkg.floors = pkg.floors.map(({id,_id,...floor}) => floor)
                return pkg
            })
            if (typeof (object.unitGroup) == 'object') object.unitGroup = object.unitGroup.id
            else if(typeof (object.unitGroup) == 'string') object.unitGroup = object.unitGroup
            object.name = `${object.name} (copy)`
            console.log('object', object)
            this.loading = true
            this.$api.post(`/units/${this.instance.id}`, object).then( ({data}) => {
                this.$store.commit('SET_PROP', {where:['units',data.id],what:data})
                this.loading = false
            }).catch(err => {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			}).finally(() => {
				this.loading = false
			})

        },
        sortUnavailable() {
            let result = this.units.filter (unit => unit.salesStatus == 'unavailable')
            this.unitList = result
            this.emptyUnit = 'UNAVAILABLE'
        },
        sortAvailable() {
            let result = this.units.filter(unit => unit.salesStatus == 'available')
            this.unitList = result
            this.emptyUnit = 'AVAILABLE'
        },
        sortHold() {
            let result = this.units.filter(unit => unit.salesStatus == 'hold')
            this.unitList = result
            this.emptyUnit = 'HOLD'
        },
        sortSold() {
            let result = this.units.filter(unit => unit.salesStatus == 'sold')
            this.unitList = result
            this.emptyUnit = 'SOLD'
        },
        sortPublished() {
            let result = this.units.filter(unit => unit.status == 'published')
            this.unitList = result
            this.emptyUnit = 'PUBLISHED'
        },
        sortUnpublished() {
            let result = this.units.filter(unit => unit.status == 'unpublished')
            this.unitList = result
            this.emptyUnit = 'UNPUBLISHED'
        }
    },
    created() {
        this.$store.commit('SET_CRUMBS', this.currentCrumbs)
    }
}
</script>

<style>
.display-unit:hover {
    background-color:var(--off-white-dark) !important;
}
.option-button {

}
.test .ant-popover-inner-content {
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
}
.option-button:hover {
    color: var(--orange);
    background-color: var(--light-gray);
    padding:10px;
    border-radius:50%;

}
.options {
    padding-left:10px;
    padding-right:30px
}
.options:hover {
    background-color: var(--off-white-dark);
}
.sales-status {
    padding:3px
}
.sales-status:hover {
    background-color: var(--off-white-dark);
}
</style>
<style scoped>
.grid-display{
    display:grid;
	gap: 1.5rem;
    grid-template-columns: repeat(4, minmax(0, 1fr));
}
</style>
